@media screen and (max-width: 640px) {
    .back-to-top {
        position: fixed;
        bottom: 1.3rem;
        left: 1rem;
        color: black;
        cursor: pointer;
        border: none;
        width: 5rem;
        background-color: transparent;
        z-index:100;

    }
    
    .email-footer{
        max-width: 40%;
    }

    .floatWPP{
        position:fixed;
        width:4.5rem;
        height:4.5rem;
        bottom:1rem;
        right:1rem;
        z-index:100;
    }
    .floatWPP img {
        width: 3.5rem;
    }
    
    footer {
        margin-top: 0.5rem;
    }
    .socialIcon{
        max-height: 2rem;
    }
    .logo{
        max-width: 8rem;
    }
    .wppFooter{
        width: 55%;
    }

}

@media screen and (min-width: 640px) and (max-width: 1280px) {
    .back-to-top {
        position: fixed;
        bottom: 1.8rem;
        left: 2rem;
        color: black;
        cursor: pointer;
        border: none;
        width: 5.4rem;
        background-color: transparent;
        z-index:100;

    }
   
    .floatWPP{
        position:fixed;
        width:4.5rem;
        height:4.5rem;
        bottom:1rem;
        right:1rem;
        z-index:100;
    }
    .floatWPP img {
        width: 3.5rem;
    }
    
    footer {
        margin-top: 1rem;
    }
    .socialIcon{
        max-height: 2rem;
    }
    .logo{
        max-width: 8rem;
    }
    .wppFooter{
        width: 55%;
    }
    .email-footer{
        max-width: 40%;
    }
}

@media screen and (min-width: 1280px) {
    .back-to-top {
        position: fixed;
        bottom: 1.5rem;
        left: 2rem;
        color: black;
        cursor: pointer;
        border: none;
        width: 6rem;
        background-color: transparent;
        z-index:100;

    }

    .floatWPP{
        position:fixed;
        width:5rem;
        height:5rem;
        bottom:2rem;
        right:2rem;
        z-index:100;
    }
    .floatWPP img{
        width: 5rem;
    }
    .logo{
        max-width: 15rem;
    }
    .socialIcon{
        height: 3.5rem;
    }

    footer {
        margin-top: 2rem !important;
    }
    .email-footer{
        max-width: 30%;
    }
    .wppFooter{
        width: 35%;
    }

}
